<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>添加评价</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table"
           style="width: 50%; margin-top: 10px">
        <el-form :model="formData"
                 ref="formDataRef"
                 label-width="100px">
          <el-form-item label="商品关联" >
            <el-select v-model="formData.groupId"
                       placeholder="请选择商品">
              <el-option v-for="(item,index) in commentGroups" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评价分数" >
            <el-select v-model="formData.comprehensiveScore" placeholder="请选择评分">
              <el-option v-for="(item,index) in scoreList" :key="index" :label="item.name" :value="item.score"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评价内容">
            <el-input type="textarea" v-model="formData.evaluateMessage"></el-input>
          </el-form-item>
          <el-form-item label="评价图片">
            <!-- 图片集 -->
            <el-upload class="upload-demo"
                       :action="uploadURL"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :on-success="handleSuccess"
                       :headers="headerObj"
                       :file-list="fileList"
                       list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb,不能超过3张图片
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="是否匿名" >
            <el-switch v-model="formData.status" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="formData.userName"></el-input>
          </el-form-item>
          <el-form-item label="用户头像" >
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-preview="mainHandlePictureCardPreview"
                       :on-remove="mainHleRemove"
                       :headers="headerObj"
                       :file-list="mainFileList"
                       :on-success="mainHandleSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="picDialogVisible">
              <img width="100%" :src="dialogImageUrl" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="评价排序">
            <el-input v-model="formData.sort"></el-input>
          </el-form-item>
          <el-form-item label="是否展示" >
            <el-switch v-model="formData.ifShow" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>

      </div>
    </el-card>

    <!-- 图片预览 -->
    <el-dialog title="图片预览" :visible.sync="previewVisible" width="80%">
      <img :src="picsdialogImageUrl" class="previewImg" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scoreList: [
          {name: '非常满意', score: 5},
          {name: '满意', score: 4},
          {name: '一般', score: 3},
          {name: '不满意', score: 2},
          {name: '非常不满意', score: 1},
      ],
      // 分页
      pages: {
        query: '',
        currentPage: 1,
        pageSize: 3,
      },
      isEdit: false,
      goodInfo: {},

      formData: {
        groupId: '',
        comprehensiveScore: 5,
        evaluateMessage: '',
        ifShow: true,
        evaluatePics: [],
        sort: '',
        status: false,
        userName: '',
        userPic: '',
      },

      mainFileList: [],
      fileList: [],
      // 声明一个图片的地址
      uploadURL: '/api/file/uploadFile',

      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },

      //图片地址
      dialogImageUrl: null,
      // 控制图片的显示隐藏
      picDialogVisible: false,

      //图片集地址
      picsdialogImageUrl: '',
      // 图片集的展示
      previewVisible: false,
      // catelist: []

      commentGroups: []
    }
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.goodInfo = localStorage.getItem('comment-' + query.id);
      if(this.goodInfo){
        this.goodInfo = JSON.parse(this.goodInfo);
        console.log(this.goodInfo)
        this.isEdit = true;
        this.formData.groupId = this.goodInfo.groupId;
        this.formData.comprehensiveScore = this.goodInfo.comprehensiveScore || 5;
        this.formData.evaluateMessage = this.goodInfo.evaluateMessage || '';
        this.formData.ifShow = this.goodInfo.ifShow == 1
        this.formData.userName = this.goodInfo.userName || ''
        this.formData.userPic = this.goodInfo.userPic || ''
        this.formData.evaluatePics = this.goodInfo.evaluatePics ? this.goodInfo.evaluatePics.split(',') : [];
        this.formData.sort = this.goodInfo.sort || 1
        this.formData.status = this.goodInfo.status == 1
        this.mainFileList.push({
          url: this.formData.userPic,
        })
        this.fileList = this.formData.evaluatePics.map(o => ({url: o}))
      }
    }
    this.loadCommentGroup();
  },
  methods: {
    loadCommentGroup () {
      this.$api.GetGoodsEvaluateGroupList({}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败')
        this.commentGroups = res.data;
        if(this.commentGroups.length && !this.isEdit){
          this.formData.groupId = this.commentGroups[0].id;
        }
      })
    },
    // 图片集移除
    handleRemove (file, fileList) {
      this.formData.evaluatePics = this.formData.evaluatePics.filter(o => o != file.url)
    },
    // 图片集预览
    handlePreview (file) {
      // console.log(file)
      this.picsdialogImageUrl = file.response.data
      this.previewVisible = true
    },
    // 图片上传完成之后
    handleSuccess (res) {
      this.formData.evaluatePics = this.formData.evaluatePics.concat([res.data])
      this.fileList.push({url: res.data})
    },

    // 图片预览
    mainHandlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.picDialogVisible = true
    },

    // 图片删除
    mainHleRemove (file, fileList) {
      this.formData.userPic = ''
      this.mainFileList = [];
      console.log(this.formData)
    },

    // 图片上传成功之后的操作
    mainHandleSuccess (res) {
      this.formData.userPic = res.data
      this.mainFileList.push({
        url: res.data,
      })
    },

    // 添加商品
    addGoods () {
      const params = {...this.formData};
      params.status = params.status ? 1 : 0;
      params.ifShow = params.ifShow ? 1 : 0;
      if(params.evaluatePics){
        params.evaluatePics = params.evaluatePics.join(',');
      }else{
        delete params.evaluatePics;
      }
      if(this.isEdit){
        this.$api.UpdateEvaluateFromGroup({...this.goodInfo, ...params}).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('修改失败')
          } else {
            this.$message.success('修改成功')
          }
          // 刷新列表
          this.$router.push('/comment')
        })
      }else{
        this.$api.AddEvaluateToGroup(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('添加失败')
          } else {
            this.$message.success('添加成功')
          }
          this.$router.push('/comment')
        })
      }
    },

  },
}
</script>

<style lang="scss" scoped></style>
